<template>
	<div class="animated fadeIn">
		<div class="table-responsive">
			<table class="table table-hover table-striped text-nowrap">
				<thead>
					<tr>
						<th />
						<th
							class="border-top-0 text-left">
							{{ translate('country') }}
						</th>
						<th
							class="border-top-0 text-right">
							{{ translate('subtotal') }}
						</th>
						<th
							class="border-top-0 text-right">
							{{ translate('sales_percent') }}
						</th>
						<th
							class="border-top-0 text-right">
							{{ translate('shipping') }}
						</th>
						<th
							class="border-top-0 text-right">
							{{ translate('duties') }}
						</th>
						<th
							class="border-top-0 text-right">
							{{ translate('total_sales') }}
						</th>
						<th
							class="border-top-0 text-right">
							{{ translate('refunds') }}
						</th>
						<th
							class="border-top-0 text-right">
							{{ translate('expenses') }}
						</th>
						<th
							class="border-top-0 text-right">
							{{ translate('net_sales') }}
						</th>
					</tr>
				</thead>
				<tbody v-if="!loading && hasData">
					<template v-for="(item, index) in data">
						<tr
							:key="index"
							:class="{
								'pointer': Object.keys(item.attributes.sales_detail).length > 0,
							}"
							@click="handleRowClick(item)">
							<td
								class="align-middle text-center">
								<em
									v-if="Object.keys(item.attributes.sales_detail).length > 0"
									:class="`fas fa-chevron-${showChild(item) ? 'up' : 'down'}`" />
							</td>
							<td class="text-left">
								<img
									:src="getFlag(item.attributes.group)"
									class="mr-2">{{ translate(item.attributes.group.toLowerCase()) }}
							</td>
							<td class="text-right">
								{{ item.attributes.subtotal }}
							</td>
							<td class="text-right">
								{{ item.attributes.subtotal_perc }}
							</td>
							<td class="text-right">
								{{ item.attributes.shipping }}
							</td>
							<td class="text-right">
								{{ item.attributes.tax }}
							</td>
							<td class="text-right">
								{{ item.attributes.total }}
							</td>
							<td class="text-right">
								{{ item.attributes.refund }}
							</td>
							<td class="text-right">
								{{ item.attributes.expenses }}
							</td>
							<td class="text-right">
								{{ item.attributes.net_sales }}
							</td>
						</tr>
						<template>
							<transition
								v-for="(countryData, cdIndex) in item.attributes.sales_detail"
								:key="cdIndex"
								name="slide-fade">
								<tr
									v-if="showChild(item)"
									:key="`${index}__${cdIndex}`"
									style="background-color: #f3f3f37a">
									<td />
									<td class="text-right">
										{{ translate(cdIndex) }}
									</td>
									<td class="text-right">
										{{ countryData.base_subtotal }}
									</td>
									<td class="text-right">
										{{ countryData.subtotal_perc }}
									</td>
									<td class="text-right">
										{{ countryData.base_shipping }}
									</td>
									<td class="text-right">
										{{ countryData.base_tax }}
									</td>
									<td class="text-right">
										{{ countryData.base_total }}
									</td>
									<td class="text-right">
										{{ countryData.base_refund }}
									</td>
									<td class="text-right">
										{{ countryData.base_expenses }}
									</td>
									<td class="text-right">
										{{ countryData.base_net_sales }}
									</td>
								</tr>
							</transition>
						</template>
					</template>
					<tr class="text-right">
						<td colspan="2">
							<b />
						</td>
						<td class="text-right">
							<b>{{ totals.subtotal }}</b>
						</td>
						<td class="text-right">
							<b>{{ totals.subtotal_perc }}</b>
						</td>
						<td class="text-right">
							<b>{{ totals.shipping }}</b>
						</td>
						<td class="text-right">
							<b>{{ totals.tax }}</b>
						</td>
						<td class="text-right">
							<b>{{ totals.total }}</b>
						</td>
						<td class="text-right">
							<b>{{ totals.refund }}</b>
						</td>
						<td class="text-right">
							<b>{{ totals.expenses }}</b>
						</td>
						<td class="text-right">
							<b>{{ totals.net_total }}</b>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<is-loading
			:loading-label="translate('loading')"
			:no-data-label="translate('data_not_found')"
			:loading="loading"
			:has-data="hasData" />
	</div>
</template>
<script>
import {
	MDY_FORMAT as mdyFormat,
} from '@/settings/Dates';
import {
	Report, Grids, PaymentMethods, Countries,
} from '@/translations';
import FiltersParams from '@/mixins/FiltersParams';
import Sales from '@/util/Report';

export default {
	name: 'SalesAll',
	messages: [Report, Grids, PaymentMethods, Countries],
	mixins: [FiltersParams],
	data() {
		return {
			sales: new Sales(),
			currentExpandIds: [],
		};
	},
	computed: {
		loading() {
			return !!this.sales.data.loading;
		},
		errors() {
			return this.sales.data.errors;
		},
		data() {
			try {
				const { data } = this.sales.data.response.data;
				return data;
			} catch (error) {
				return [];
			}
		},
		hasData() {
			const response = this.data.length;
			return !!response;
		},
		totals() {
			try {
				const { totals } = this.sales.data.response.data.meta;
				return totals;
			} catch (error) {
				return {};
			}
		},
	},
	mounted() {
		this.$parent.$parent.$parent.rangeStart = '';
		this.$parent.$parent.$parent.rangeEnd = '';
		this.sales.getSalesByCountry().then(() => {
			const { start, end } = this.sales.data.response.data.meta.period_dates;
			this.$parent.$parent.$parent.rangeStart = this.$moment(start.date).format(mdyFormat);
			this.$parent.$parent.$parent.rangeEnd = this.$moment(end.date).format(mdyFormat);
		});
	},
	methods: {
		getDataFiltered() {
			const { query } = this.$route;
			const options = { ...query, ...this.filters };
			this.sales.getSalesByCountry(options);
		},
		getFlag(src, isSmall) {
			let path = 'header';
			if (isSmall === true) {
				path = 'select';
			}
			try {
				/* eslint-disable global-require */
				/* eslint-disable import/no-dynamic-require */
				return require(`@/assets/images/common/countries/${path}/${src}.png`);
			} catch (error) {
				return require(`@/assets/images/common/countries/${path}/default.png`);
			}
		},
		handleRowClick(item) {
			if (item.attributes.sales_detail.length === 0) {
				return;
			}
			if (this.currentExpandIds.includes(Number(item.id))) {
				this.currentExpandIds = this.currentExpandIds.filter((id) => id !== Number(item.id));
			} else {
				this.currentExpandIds.push(Number(item.id));
			}
		},
		showChild(item) {
			return this.currentExpandIds.includes(Number(item.id));
		},
	},
};
</script>

<style>
	.division-right {
		border-right: solid 1px #ccc;
	}
</style>
